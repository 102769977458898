/**
 * 公用方法(全局公用或者多个页面以上方法公用可放)
 */

import { Toast } from 'vant';
import Vconsole from 'vconsole';
import {
    appBack,
    appType
} from "../lib/appMethod";
// 控制路由，保证路由为单次跳转
let shouldBackState = true;
const mixins = {
    data() {
        return {
            // appTypeStr:appType(), // 应用类型
            // openConsoleLog: true, // 控制vconsole显示
            // baseURL: '', // 微信url
            // baseURLApp: '', // 原生的url
            // baseURLCoachApp: '', // 教练端的url
            // isTestEnvironment: false,
            // locationUrl: 'https://web.chaolu.com.cn/webdark'
        }
    },
    created() {
        // this.jugeDomainUrl();
    },
    methods: {
        // 判断接口请求地址
        // jugeDomainUrl() {
        //     var hostName = window.location.hostname
        //     var host = hostName.split('.')[0]
        //     var isWhiteList = (hostName === '47.98.46.218') || (hostName === '47.97.160.180') || (hostName === 'web.chaolu.com.cn')
        //     var hostArr = ['wx', 'wxs', 'app', 'apps', 'coach']
        //     if (hostArr.includes(host) || isWhiteList) {
        //         this.baseURL = 'https://wxs.chaolu.com.cn'
        //         // this.baseURLApp =  appType() === 'mini'?'https://apps.chaolu.com.cn' :'//app.chaolu.com.cn:80'
        //         // this.baseURLApp =   'http://112.124.8.69:80'
        //         this.baseURLApp = 'https://app.chaolu.com.cn'
        //         // this.baseURLApp = 'http://app.chaolu.com.cn:80'
        //         this.baseURLCoachApp = 'http://coach.chaolu.com.cn:8080'
        //         this.locationUrl = 'https://web.chaolu.com.cn'
        //         this.openConsoleLog = false
        //     } else if(hostName === 'webdark.chaolu.com.cn'){
        //         this.baseURLApp = 'http://dark.chaolu.com.cn'
        //         this.locationUrl = 'http://webdark.chaolu.com.cn'
        //         this.isTestEnvironment = true
        //     } else{
        //         this.baseURL = 'http://wxtest.chaolu.com.cn:80'
        //         this.baseURLApp = 'http://apptest.chaolu.com.cn'//'//192.168.1.218:9999'
        //         this.baseURLCoachApp = 'http://coachtest.chaolu.com.cn'
        //         this.locationUrl = window.location.origin
        //         this.isTestEnvironment = true
        //     }
        //     if (this.openConsoleLog) {
        //         this.newVConsoleOnce()
        //     }
        // },
        // 确保不与组件重复执行vconsole， 只执行一次vconsole
        newVConsoleOnce() {
            try {
                if (window.console.time.toString().indexOf('Date.now') === -1) {
                    new Vconsole()
                    window.Vconsole = function () {
                        console.log('wjw vconsole')
                    }
                }
            } catch (e) {
                console.log('new VConsole error')
            }
        },
        // 跳转地址,push,不传参
        getmodel(routerName) {
            this.$router.push(routerName);
        },
        // 带参数跳转（push）
        getmodelParam(routerName, params) {
            this.$router.push({
                name: routerName,
                query: params
            })
        },
        // replace替换路由
        replaceModel(routerName) {
            this.$router.replace(routerName);
        },
        // replace替换路由并传参
        replaceModelParam(routerName, params) {
            this.$router.replace(routerName, params);
        },
        // 提示
        tips(str, times = 2000) {
            Toast({
                message: "" + str,
                duration: times
            });
        },
        // 返回
        back() {
            let that = this;
            that.dealHistory(() => {
                that.jugeBackGoto(-1);
            });
        },
        // 不启用返回时候(此方法有些问题，返回时候如果只返回一层可以使用)
        jugeBackGoto(num) {
            shouldBackState = false;
            this.$router.back(num);
        },
        //判断历史记录
        jugeHistory() {
            let that = this;
            if (typeof plus == "undefined") {
                window.addEventListener(
                    "popstate",
                    function () {
                        /**
                         * 判断作用：如果是自定义路由就不要再次调用方法
                         * */
                        if (shouldBackState) {
                            // console.log("调用历史记录返回")
                            that.dealHistory();
                        } else {
                            // console.log('进来,此时历史记录没有')
                            shouldBackState = true;
                        }
                    },
                    false
                );
            }
        },
        // 此处处理路由跳转
        dealHistory(fn = function () {
        }) {
            // let that = this;
            // 判断当前页，是否是上一页
            // let pageLink = JSON.parse(sessionStorage.getItem("pageLink"));
            // console.log(pageLink);
            /**
             * lastPage 上一页
             * currentPage 当前页
             * */
            if (
                sessionStorage.getItem("router_name") == "home" ||
                sessionStorage.getItem("router_name") == "null"
            ) {
                // 根路由控制
                window.history.pushState("forward", null, "#");
                return;
            }
            fn();
        },
        doBack(fn = function () {
        }) {
            window.history.pushState("forward", null, "#");
            fn();
        },
        countPoint(activityType, activityId, templateId, userId = 0, ext1, ext2, ext3) {
            if (process.env.NODE_ENV == "production") {
                this.$axios.post(this.baseURLApp + '/dataPoint/dataPoint', {
                    userId: userId ? userId : this.userId ? this.userId : 0,
                    activityType: activityType,
                    activityId: activityId,
                    templateId: templateId,
                    count: "0",
                    amount: "0",
                    ext1,
                    ext2,
                    ext3,
                }).then((res) => {
                    console.log('埋点', activityType, activityId, templateId)
                })
            }
        },
        /**
     * 小程序广告转化
     * @param actionType 预定义的行为类型，目前只支持COMPLETE_ORDER（下单）及RESERVATION（表单预约）、CONFIRM_EFFECTIVE_LEADS（有效销售线索）
     */
        addUserAction(actionType, param = '') {
            if (process.env.NODE_ENV == "production") {
                const clickId = (this.$route.query.clickId || this.$route.query.gdt_vid)
                if (!clickId) {
                    return
                }
                this.$axios.post(`${this.baseURLApp}/user/behavior/tx/data-nexus/ad-track-data/add`, {
                    clickId,
                    actionType: actionType,
                    userId: this.userId,
                    param
                }, false).then((res) => {
                    console.log('广告数据转化埋点：类型', actionType, '返回参数', res)
                }).catch((r) => {
                    console.log('广告数据转化埋点：类型', actionType, '埋点接口调用失败')
                })
            }

        },
        appBack: appBack,
    }
};
export default mixins;
