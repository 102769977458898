const routes = [
    /**
     * 活动路由
     */
    {
        "path":"/superdeer-activity/sell-membership",
        "name":"sell-membership",
        "meta":{"title":"超鹿月卡","headerType":5},
        component:() => import('../views/superdeer-activity/sell-membership/index')
    },
    {
        "path":"/superdeer-activity/sell-membership/schedule-month",
        "name":"sell-membership",
        "meta":{"title":"超鹿包月团课卡","headerType":5},
        component:() => import('../views/superdeer-activity/sell-membership/schedule-month')
    },
    {
        "path":"/superdeer-activity/sell-membership/star-coach",
        "name":"star-coach",
        "meta":{"title":"团课明星教练","headerType":5},
        component:() => import('../views/superdeer-activity/sell-membership/star-coach')
    },
    {
        "path":"/superdeer-activity/sell-membership/self",
        "name":"sell-membership-self",
        "meta":{"title":"超鹿自助健身卡","headerType":5},
        component:() => import('../views/superdeer-activity/sell-membership/self')
    },
    {
        "path":"/superdeer-activity/sell-membership/schedule",
        "name":"sell-membership-schedule",
        "meta":{"title":"超鹿团课健身卡","headerType":5},
        component:() => import('../views/superdeer-activity/sell-membership/schedule')
    },
    {
        "path":"/superdeer-activity/sell-membership/coach",
        "name":"sell-membership-coach",
        "meta":{"title":"超鹿包月私教","headerType":5},
        component:() => import('../views/superdeer-activity/sell-membership/coach')
    },
    {
      "path":"/superdeer-activity/sell-membership/coach-specialbag",
      "name":"sell-membership-coach-specialbag",
      "meta":{"title":"包月私教卡特殊课包","headerType":5},
      component:() => import('../views/superdeer-activity/sell-membership/coach-specialbag')
    },
    {
      "path":"/superdeer-activity/sell-membership/coach-upgrade",
      "name":"sell-membership-coach-upgrade",
      "meta":{"title":"包月私教卡升级","headerType":5},
      component:() => import('../views/superdeer-activity/sell-membership/coach-upgrade')
    },
    {
      "path":"/superdeer-activity/sell-membership/monthcard-upgrade",
      "name":"sell-membership-coach-monthcard-upgrade",
      "meta":{"title":"超鹿月卡升级","headerType":5},
      component:() => import('../views/superdeer-activity/sell-membership/monthcard-upgrade')
    },
    {
        "path":"/superdeer-activity/sell-membership/coupon",
        "name":"sell-membership",
        "meta":{"title":"我的自助优惠卡","headerType":4},
        component:() => import('../views/superdeer-activity/sell-membership/coupon')
    },
    // 青少年体育 私教专题页
    {
        "path":"/superdeer-activity/groupCoach",
        "name":"groupCamp",
        "meta":{"title":"青少年体育全项提升","headerType":4},
        component:() => import('../views/superdeer-activity/group-coach-2022/index')
    },
    {
        "path":"/superdeer-activity/new-people",
        "name":"new-people",
        "meta":{"title":"新人福利","headerType":4},
        component:() => import('../views/superdeer-activity/new-people-2022/index')
    },
    {
        "path":"/superdeer-activity/new-people/home",
        "name":"new-people-home",
        "meta":{"title":"新人福利","headerType":4},
        component:() => import('../views/superdeer-activity/new-people-2022/home')
    },
    {
        "path":"/superdeer-activity/recruitment",
        "name":"recruitment",
        "meta":{"title":"超鹿招聘","headerType":4},
        component:() => import('../views/superdeer-activity/recruitment/index')
    },
    {
        "path":"/superdeer-activity/free-train",
        "name":"free-train",
        "meta":{"title":"免费领取训练计划","headerType":4},
        component:() => import('../views/superdeer-activity/recruitment/free-train')
    },
    {
        "path": "/superdeer-activity/micro-popularize",
        "name": "micro-popularize",
        "meta": { "title": "超鹿燃脂小班课·公开课", "headerType": 4 },
        component:() => import('../views/superdeer-activity/recruitment/micro-popularize')
    },
    /**
     * 常驻路由
     */
    // 团课月卡-2022
    {
        "path":"/month-card/my-card",
        "name":"month-card-my-card",
        "meta":{"title":"我的卡包","headerType":5,keepAlive:true},
        component:() => import('../views/month-card/my-card')
    },
    {
      "path":"/month-card/auto-renew-manage",
      "name":"auto-renew-manage",
      "meta":{"title":"自动续费管理","headerType":5,keepAlive:true},
      component:() => import('../views/month-card/auto-renew-manage')
    },
    {
        "path":"/month-card/sell-record",
        "name":"month-card-sell-record",
        "meta":{"title":"开卡记录","headerType":5},
        component:() => import('../views/month-card/sell-record')
    },
    {
        "path":"/month-card/my-card-detail",
        "name":"month-card-my-card-detail",
        "meta":{"title":"权益卡详情","headerType":5},
        component:() => import('../views/month-card/my-card-detail')
    },
    // 团课月卡详情
    {
        "path":"/month-card/my-schedule-detail",
        "name":"month-card-my-schedule-detail",
        "meta":{"title":"团课会员卡","headerType":5},
        component:() => import('../views/month-card/my-schedule-detail')
    },
    {
        "path":"/month-card/sign-rule",
        "name":"month-card-sing-rule",
        "meta":{"title":"限定免费团课签到规则","headerType":5},
        component:() => import('../views/month-card/sign-rule')
    },
    {
        "path":"/month-card/equity-detail",
        "name":"month-card-equity",
        "meta":{"title":"包含权益","headerType":5},
        component:() => import('../views/month-card/equity-detail')
    },
    {
        "path":"/month-card/use-record",
        "name":"month-card-use-record",
        "meta":{"title":"券包","headerType":5},
        component:() => import('../views/month-card/use-record')
    },
    {
        "path":"/month-card/use-record-self",
        "name":"month-card-use-record-self",
        "meta":{"title":"使用情况","headerType":5},
        component:() => import('../views/month-card/use-record-self')
    },
    {
        "path":"/month-card/equity-gift/list",
        "name":"equity-gift",
        "meta":{"title":"赠送优惠券","headerType":5},
        component:() => import('../views/month-card/equity-gift/list')
    },
    {
        "path":"/month-card/equity-gift/receive",
        "name":"equity-gift",
        "meta":{"title":"领取优惠券","headerType":5},
        component:() => import('../views/month-card/equity-gift/receive')
    },
    {
        "path":"/createQrcode",
        "name":"createQrcode",
        "meta":{"title":"生成二维码","headerType":5},
        component:() => import('../views/createQrcode')
    },
    // 赠送月卡给好友
    {
        "path":"/give-equity-card",
        "name":"give-equity-card",
        "meta":{"title":"发送卡券红包","headerType":4},
        component:() => import('../views/give-equity-card/index')
    },
    // 赠送月卡给好友
    {
        "path":"/give-equity-card/record",
        "name":"give-equity-card-record",
        "meta":{"title":"赠礼记录","headerType":4},
        component:() => import('../views/give-equity-card/record')
    },
    { "path": "/appEmptyPage", "name": "appEmptyPage", "meta": { "title": "课包抵扣券", "headerType": 2 }, component: () => import('../views/appEmptyPage') },
    { "path": "/superdeer-activity/banner/cash-voucher", "name": "cash-voucher", "meta": { "title": "超鹿运动", "headerType": 2 }, component: () => import('../views/superdeer-activity/banner/cash-voucher') },
    { "path": "/mini-team/my-team", "name": "mini-team-my-team", "meta": { "title": "我的战队", "headerType": 5 }, component: () => import('../views/mini-team/my-team.vue') },
    { "path": "/temp-activity/swimming-mall", "name": "swimming-mall", "meta": { "title": "超鹿游泳周边商城", "headerType": 4 }, component: () => import('../views/temp-activity/swimming-mall.vue') },
    { "path": "/temp-activity/sdcollege-mall", "name": "sdcollege-mall", "meta": { "title": "超鹿学院商城", "headerType": 4 }, component: () => import('../views/temp-activity/sdcollege-mall.vue') },
    { "path": "/temp-activity/54bei-mall", "name": "student-mall", "meta": { "title": "五四北泰禾停车券", "headerType": 4 }, component: () => import('../views/temp-activity/54bei-mall.vue') },
    { "path": "/temp-activity/card-mall", "name": "card-mall", "meta": { "title": "实体卡商城", "headerType": 4 }, component: () => import('../views/temp-activity/card-mall.vue') },
    { "path": "/feedback-record", "name": "feedback-record", "meta": { "title": "反馈记录", "headerType": 5, keepAlive: true }, component: () => import('../views/feedback-record/index.vue') },
    { "path": "/feedback-record/detail", "name": "feedback-record-detail", "meta": { "title": "反馈详情", "headerType": 5 }, component: () => import('../views/feedback-record/detail.vue') },
    { "path": "/feedback-record/scratch", "name": "feedback-record-scratch", "meta": { "title": "反馈赢好礼", "headerType": 5 }, component: () => import('../views/feedback-record/scratch.vue') },

    { "path": "/membership-rule", "name": "membership-rule", "meta": { "title": "等级规则", "headerType": 5 }, component: () => import('../views/membership/rule.vue') },
    { path: '/temp-activity/get-jinping-voucher', name: 'report2022', meta: { title: '超鹿健康权益礼包', headerType: 2 }, component: () => import(/* webpackChunkName: "report" */ '../views/temp-activity/get-jinping-voucher.vue') },

]
module.exports = routes
