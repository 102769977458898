import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import {closeWindow} from '@/lib/appMethod';
let setPop=false;//ios缓存
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:null,//用户
    userId:null,//用户
    userData:null,//用户
    teacherToken:null,//老师
    teacherUserId:null,//老师
    teacherId:null,//老师
    local:null,//用户经纬度信息
    cityId:null,//城市ID
    companyUser:null,//企业用户信息
    venueId: null,
    companyAdmin:null,//企业admin信息
    isdev:process.env.NODE_ENV=="development",
    openByApp:true,//初始是否从app或者小程序打开才会执行关闭而不是后退
    leavePath:'',//从哪个页面离开
    showPayPopup: false, // 展示支付弹窗
    payData: {}, // 支付参数
    payUrl: '', // 小程序支付回调参数
    flagJson:JSON.parse(sessionStorage.getItem('flagJson')||'{}'), //生日礼体测数据
    loginPopupShow: false,
    upgradeShow: false,
    appVersion: '', // 当前app版本号
  },
  mutations: {
    openLoginPopup(state, show) {
			state.loginPopupShow = show;
		},
    openUpgradePopup(state, show) {
			state.upgradeShow = show;
		},
    setToken(state, token) {
			state.token = token;
		},
		setUserId(state, userId) {
			state.userId=userId;
		},
    setUserData(state, userData) {
			state.userData=userData;
		},
    setAppVersion(state, appVersion) {
			state.appVersion = appVersion;
		},
    setTeacherToken(state, teacherToken) {
			state.teacherToken = teacherToken;
		},
    setTeacherId(state, teacherId) {
			state.teacherId = teacherId;
		},
    setTeacherUserId(state, teacherUserId) {
			state.teacherUserId = teacherUserId;
		},
    setLocal(state, local) {
			state.local=local;
		},
    setCityId(state, cityId) {
			state.cityId=cityId;
		},
    setVenueId(state, venueId) {
			state.venueId=venueId;
		},
    setLeavePath(state, leavePath) {
			state.leavePath=leavePath;
		},
    setOpenByApp(state, openByApp) {
			state.openByApp=openByApp;
      const startPath=router.currentRoute.path;
      if(!sessionStorage.getItem('startPath')){
        sessionStorage.setItem('startPath',startPath);//初始打开页面
      }
      if(openByApp){
        const popfunc=function (e) {
          if (e.state) {
            if(state.leavePath===sessionStorage.getItem('startPath')){
              closeWindow();
            }
          }
        }
        if(!setPop){
          //ios从别的html跳回来有缓存popstate还在，不再监听
          window.addEventListener('popstate', popfunc, false);
          setPop=true;
        }

      }
		},
    setCompanyUser(state,companyUser){
      state.companyUser=companyUser
    },
    setCompanyAdmin(state,companyAdmin){
      state.companyAdmin=companyAdmin
    },
    setFlagJson(state,flagJson){
      state.flagJson={...state.flagJson,...flagJson}
      sessionStorage.setItem('flagJson',JSON.stringify(state.flagJson))
    },
    resetFlagJson(state){
      state.flagJson={};
      sessionStorage.removeItem('flagJson')
    },
    setShowPayPopup(state,val){
      state.showPayPopup=val
    },
    setPayData(state, val){
      state.payData=val
    },
    setPayUrl(state, val){
      state.payUrl=val
    }
  },
  actions: {
    setToken(context, token) {
			context.commit('setToken', token);
		},
		setUserId(context, userId) {
			context.commit('setUserId', userId);
		},
    setUserData(context, userData) {
			context.commit('setUserData', userData);
		},
    setAppVersion(context, appVersion) {
			context.commit('setAppVersion', appVersion);
		},
  },
  modules: {
  }
})
